// WaGen___HASHKEY__d38d65d1_2023-12-08 09:30:38 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
未提供注释
*/
/* ---
标准CRUD方法：添加记录
*/
export function ykGfywxx_add(entity, meta) {
	return fetch({
		url : 'psdmsschjy/schjyschema/YkGfywxx/add',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //YkGfywxx
		}
	})
}

/* ---
标准CRUD方法：更新记录,需要的主键字段：ywbh : Longqdrq : Date
*/
export function ykGfywxx_update(entity, meta) {
	return fetch({
		url : 'psdmsschjy/schjyschema/YkGfywxx/update',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //YkGfywxx
		}
	})
}

/* ---
标准CRUD方法：删除记录,需要的主键字段：ywbh : Longqdrq : Date
*/
export function ykGfywxx_remove(entity, meta) {
	return fetch({
		url : 'psdmsschjy/schjyschema/YkGfywxx/remove',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //YkGfywxx
		}
	})
}

/* ---
标准CRUD方法：条件删除
*/
export function ykGfywxx_selectiveDelete(entity, meta) {
	return fetch({
		url : 'psdmsschjy/schjyschema/YkGfywxx/selectiveDelete',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //YkGfywxx
		}
	})
}

/* ---
标准CRUD方法：条件更新
*/
export function ykGfywxx_selectiveUpdate(entity, meta) {
	return fetch({
		url : 'psdmsschjy/schjyschema/YkGfywxx/selectiveUpdate',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //YkGfywxx
		}
	})
}

/* ---
标准CRUD方法：条件插入
*/
export function ykGfywxx_selectiveAdd(entity, meta) {
	return fetch({
		url : 'psdmsschjy/schjyschema/YkGfywxx/selectiveAdd',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //YkGfywxx
		}
	})
}

/* ---
标准CRUD方法：按主键查询，需要的主键字段：ywbh : Longqdrq : Date
*/
export function ykGfywxx_fetch(entity, meta) {
	return fetch({
		url : 'psdmsschjy/schjyschema/YkGfywxx/fetch',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //YkGfywxx
		}
	})
}

/* ---
标准CRUD方法：查询所有记录
*/
export function ykGfywxx_fetchAll(pagination, meta) {
	return fetch({
		url : 'psdmsschjy/schjyschema/YkGfywxx/fetchAll',
		method : 'post',
		headers : {
		},
		data : {
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function ykGfywxx_selectiveGet(entity, pagination, meta) {
	return fetch({
		url : 'psdmsschjy/schjyschema/YkGfywxx/selectiveGet',
		method : 'post',
		headers : {
		},
		data : {
			param : entity, //YkGfywxx
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function ykGfywxx_selectiveGetOne(entity, meta) {
	return fetch({
		url : 'psdmsschjy/schjyschema/YkGfywxx/selectiveGetOne',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //YkGfywxx
		}
	})
}

/* ---
标准CRUD方法：条件计数
*/
export function ykGfywxx_selectiveCount(entity, meta) {
	return fetch({
		url : 'psdmsschjy/schjyschema/YkGfywxx/selectiveCount',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //YkGfywxx
		}
	})
}

/* ---
未提供注释
*/
export function ykGfywxx_getYWBH(meta) {
	return fetch({
		url : 'psdmsschjy/schjyschema/YkGfywxx/getYWBH',
		method : 'post',
		headers : {
		},
		data : {
		}
	})
}

/* ---
未提供注释
*/
export function ykGfywxx_getSyCount(jgbm, meta) {
	return fetch({
		url : 'psdmsschjy/schjyschema/YkGfywxx/getSyCount',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm //String
			}
		}
	})
}

/* ---
未提供注释
*/
export function ykGfywxx_getOneByYwbh(ywbh, pagination, meta) {
	return fetch({
		url : 'psdmsschjy/schjyschema/YkGfywxx/getOneByYwbh',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				ywbh : ywbh //Long
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function ykGfywxx_getYkGfywxxlist(jgbm,dybm,ywbh,yhbh,dqzt,ssqrq,esqrq,lxsj,xmmc, pagination, meta) {
	return fetch({
		url : 'psdmsschjy/schjyschema/YkGfywxx/getYkGfywxxlist',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				dybm : dybm, //String
				ywbh : ywbh, //Long
				yhbh : yhbh, //String
				dqzt : dqzt, //List<String>
				ssqrq : ssqrq, //String
				esqrq : esqrq, //String
				lxsj : lxsj, //String
				xmmc : xmmc //String
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function ykGfywxx_getYkGfywxxForMobile(yhbh, pagination, meta) {
	return fetch({
		url : 'psdmsschjy/schjyschema/YkGfywxx/getYkGfywxxForMobile',
		method : 'post',
		headers : {
		},
		data : {
			param : yhbh, //List<String>
			pagination : pagination
		}
	})
}

/* ---
多记录插入
*/
export function ykGfywxx_insertList(list, meta) {
	return fetch({
		url : 'psdmsschjy/schjyschema/YkGfywxx/insertList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<YkGfywxx>
		}
	})
}

/* ---
多记录更新,需要的主键字段：ywbh : Longqdrq : Date
*/
export function ykGfywxx_updateList(list, meta) {
	return fetch({
		url : 'psdmsschjy/schjyschema/YkGfywxx/updateList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<YkGfywxx>
		}
	})
}

/* ---
多记录删除,需要的主键字段：ywbh : Longqdrq : Date
*/
export function ykGfywxx_deleteList(list, meta) {
	return fetch({
		url : 'psdmsschjy/schjyschema/YkGfywxx/deleteList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<YkGfywxx>
		}
	})
}

