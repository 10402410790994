<template>
  <page-view :title="pageTitle" :nav-border="false" left-arrow>
    <template #nav-right>
      <yhbh-select v-model="form.yhbh" @confirm="yhbhChangeHandle" :custom-style="{}" v-show="isNil!=1"/>
    </template>
    <loading-layout ref="loadingLayoutOuter"  error-text="绑定户号信息查询失败" empty-text="未绑定用户编号">
        <template #empty>
          <yhbh-empty fromPage="yhzd-query"/>
        </template>
    </loading-layout>
    <loading-layout ref="loadingLayout" :error-text="pageErrortext">
      <template #empty>
        <van-empty :description="pageErrortext" :image="require('@/assets/images/load-error.png')">
          <van-button type="primary" class="bottom-button" @click="pageBack" size="small">返回</van-button>
        </van-empty>
      </template>

    </loading-layout>
    <van-form :show-error="true" :show-error-message="false" :scroll-to-error="true" :validate-first="true"
              @submit="onSubmit" label-width="auto" input-align="right">
      <van-cell-group>
        <van-field
            name="isNil"
            label="非系统户"
            input-align="right">
          <template #input>
            <van-switch v-model="isNil" size="20" active-value="1" inactive-value="0" @change="handleIsNilChange" :disabled="typeDisabled"/>
          </template>
        </van-field>
        <zzjg-select
            v-model="form.jgbmList"
            :label="'组织机构（所在县、区分公司）'"
            label-width="150"
            :sjzzbm="sjzzbm"
            :required="true"
            :disabled="isNil != 1 || typeDisabled"
            @confirm="zzjgConfirm"/>
        <hsdy-select
            ref="hsdySelect"
            v-model="form.dybmList"
            :label="'核算单元（所在乡镇、街道供电组）'"
            label-width="150"
            :jgbm="form.jgbm"
            :required="true"
            :disabled="isNil != 1 || typeDisabled"
            @confirm="hsdyConfirm"/>
        <van-field required :readonly="typeDisabled" v-model="form.yhbh" name="yhbh" label="用电户户号" readonly :placeholder="isNil!=1?'请选择用电户户号':'请输入用电户户号'" :rules="[{ required: true, message: '请选择户号'}]"/>
        <van-field :readonly="isNil != 1 || typeDisabled" :required="isNil != 1?false:true" v-model="form.yhmc" name="yhmc" label="用电客户名称" placeholder="请输入用电客户名称" :rules="[{ required: true, message: '请输入用电客户名称' }]"/>
        <van-field :readonly="isNil != 1 || typeDisabled" :required="isNil != 1?false:true" v-model="form.yddz" name="yddz" label="用电客户地址" placeholder="请输入用电客户地址" :rules="[{ required: true, message: '请输入用电客户地址' }]"/>
      </van-cell-group>
      <van-cell-group class="mt-10">
        <van-field required :readonly="typeDisabled" v-model="form.xmmc" name="xmmc" label="项目名称" placeholder="请输入项目名称" :rules="[{ required: true, message: '请输入项目名称' }]"/>
        <van-field :required="form.yhbh?false:true" :readonly="typeDisabled" v-model="form.xmjsf" name="xmjsf" label="项目建设方(如与用电户一致不填)" placeholder="请输入项目建设方" :rules="[{ required: true, message: '请输入项目建设方' }]"/>
        <van-field required v-model="form.ydlbmc" name="ydlb" label="用电类别" placeholder="请选择用电类别" readonly is-link @click="ydlbShow=true" :rules="[{ required: true, message: '请选择用电类别' }]"/>
        <van-popup v-model="ydlbShow" position="bottom" v-if="!typeDisabled">
          <van-picker
              title="用电类别"
              show-toolbar
              :columns="zfdmOptions['GFYDLB']"
              :default-index="ydlbDefaultIndex"
              value-key="dmnr"
              @confirm="onYdlbConfirm"
              @cancel="onYdlbCancel"
          />
        </van-popup>
        <van-field required name="bwdy" label="并网电压(V)" placeholder="请选择并网电压" readonly @click="bwdyShow=true">
          <template #input>
            <van-radio-group v-model="form.bwdy" direction="horizontal" :disabled="typeDisabled" style="display: flex;flex-wrap: wrap;justify-content: space-between;">
              <van-radio :name="item.dmbh" v-for="(item,index) in zfdmOptions['GFBWDY']">{{item.dmnr}}</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field required name="ywlb" label="业务类别" placeholder="请选择业务类别" readonly @click="ywlbShow=true">
          <template #input>
            <van-radio-group v-model="form.ywlb" direction="horizontal" :disabled="typeDisabled" style="display: flex;flex-wrap: wrap;justify-content: space-between;">
              <van-radio :name="item.dmbh" v-for="(item,index) in zfdmOptions['GFYWLB']">{{item.dmnr}}</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field required :readonly="typeDisabled" v-if="form.ywlb == 4" v-model="form.ywlbnr" name="ywlbnr" label="" placeholder="请输入其他业务类别"/>
        <van-field required name="xnfs" label="消纳方式" placeholder="请选择消纳方式" readonly @click="xnfsShow=true">
          <template #input>
            <van-radio-group v-model="form.xnfs" direction="horizontal" :disabled="typeDisabled" style="display: flex;flex-wrap: wrap;justify-content: space-between;">
              <van-radio :name="item.dmbh" v-for="(item,index) in zfdmOptions['GFXNFS']">{{item.dmnr}}</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field required :readonly="typeDisabled" v-model="form.yyrl" name="yyrl" label="原有容量(KW)" placeholder="请输入原有容量" :rules="[{ required: true, message: '请输入原有容量' }]"/>
        <van-field required :readonly="typeDisabled" v-model="form.zjrl" name="zjrl" label="增(减)容量(KW)" placeholder="请输入增(减)容量" :rules="[{ required: true, message: '请输入增(减)容量' }]"/>
        <van-field required :readonly="typeDisabled" v-model="form.xnyzrl" name="xnyzrl" label="新能源总容量(KW)" placeholder="请输入新能源总容量" :rules="[{ required: true, message: '请输入新能源总容量' }]"/>
      </van-cell-group>
      <van-cell-group class="mt-10">
        <van-field required name="xmjsfsf" label="项目建设方身份" label-width="60px">
          <template #input>
            <van-radio-group v-model="form.xmjsfsf" direction="horizontal" :disabled="typeDisabled">
              <van-radio name="1">房屋产权人（业主）</van-radio>
              <van-radio name="2">租/借/合作</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field required :readonly="typeDisabled" v-model="form.lxr" name="lxr" label="联系人" placeholder="请输入联系人" :rules="[{ required: true, message: '请输入联系人' }]"/>
        <van-field required :readonly="typeDisabled" v-model="form.lxsj" name="lxsj" label="联系电话" placeholder="请输入联系电话" :rules="[{ required: true, message: '请输入联系电话' }]"/>
        <van-field required :readonly="typeDisabled" v-model="form.lxdz" name="lxdz" label="联系人地址" placeholder="请输入联系人地址" :rules="[{ required: true, message: '请输入联系人地址' }]"/>
      </van-cell-group>
      <van-cell-group class="mt-10" v-if="fileList.length">
        <van-cell required title="已上传文件" class="van-cell--gzdfj">
          <van-uploader
            v-model="fileList"
            :max-count="fileList.length"
            :preview-image="true"
            disabled
            :deletable="false"
          >
            <template #preview-cover="{ file }">
              <div class="preview-cover van-ellipsis">{{ file.name }}</div>
            </template>
          </van-uploader>
        </van-cell>
      </van-cell-group>
      <van-cell-group class="mt-10" v-if="!typeDisabled">
        <van-cell required title="投资方身份证明材料" class="van-cell--gzdfj">
          <gzd-fj-uploader ref="fjUploaderTzfsfzm" :gzdxx="gzdxx" :yhbh="form.yhbh" :gnid="gnid" bzsm="投资方身份证明材料"/>
        </van-cell>
        <van-cell required title="用电权属证明材料" class="van-cell--gzdfj">
          <gzd-fj-uploader ref="fjUploaderYdqszm" :gzdxx="gzdxx" :yhbh="form.yhbh" :gnid="gnid" bzsm="用电权属证明材料"/>
        </van-cell>
        <van-cell required title="项目备案证明材料" class="van-cell--gzdfj">
          <gzd-fj-uploader ref="fjUploaderXmbazm" :gzdxx="gzdxx" :yhbh="form.yhbh" :gnid="gnid" bzsm="项目备案证明材料"/>
        </van-cell>
        <van-cell title="其他" class="van-cell--gzdfj">
          <gzd-fj-uploader ref="fjUploaderQt" :gzdxx="gzdxx" :yhbh="form.yhbh" :gnid="gnid" bzsm="其他"/>
        </van-cell>
      </van-cell-group>
      <van-cell-group class="mt-10" v-if="type!=1">
        <van-cell :border="false" title="申请日期：" v-if="form.qdrq">{{form.qdrq}}</van-cell>
        <van-cell :border="false" title="受理日期：" v-if="form.slTime">{{form.slTime}}</van-cell>
        <van-cell :border="false" title="审核日期：" v-if="form.shrq">{{form.shrq}}</van-cell>
        <van-cell :border="false" title="审核结果：" v-if="form.shjg == 1 || form.shjg == 2">
          <van-tag :type="form.shjg == 1?'success':'danger'">{{form.shjg == 1?'审核通过':'审核未通过'}}</van-tag>
        </van-cell>
        <van-cell :border="false" title="审核原因：" v-if="form.shyy">{{form.shyy}}</van-cell>
        <van-cell :border="false" title="暂缓说明：" v-if="form.zhsm">{{form.zhsm}}</van-cell>
        <van-cell :border="false" title="勘察说明：" v-if="form.kcsm">{{form.kcsm}}</van-cell>
      </van-cell-group>
      <div style="margin: 16px;display: flex;" class="text-center" v-if="!typeDisabled">
        <van-button type="primary" native-type="submit" class="mr-10" size="large">提交</van-button>
        <van-button type="primary" native-type="button" plain size="large" @click="pageBack">返回</van-button>
      </div>
    </van-form>
  </page-view>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { Uploader } from 'vant';
  import YhbhEmpty from '@/components/YhbhEmpty/index.vue';
  import YhbhSelect from '@/components/YhbhSelect/index.vue';
  import ZzjgSelect from '@/components/zzjg-select/index.vue';
  import HsdySelect from '@/components/hsdy-select/index.vue';
  import GzdFjUploader from '@/components/GzdFjUploader';
  import {zfdmManageService_getZfdmByDmzl} from "@/api/zfdm";
  import {ykGfService_addGfywxx} from "@/api/psdmsschjy/service/YkGfServiceAPI.js"
  import {ykGfywxx_selectiveGetOne} from "@/api/psdmsschjy/schjyschema/YkGfywxxAPI";
  import {fileManage_getList} from "@/utils/fileManage";

  export default {
    name: 'gfbwsq',
    components: {
      YhbhEmpty,
      YhbhSelect,
      ZzjgSelect,
      HsdySelect,
      GzdFjUploader,
      [Uploader.name]: Uploader,
    },
    data() {
      return {
        pageTitle: "光伏并网申请",
        pageErrortext: '',
        yhbh: null,
        yhJbxx: {},
        sbjhxx:{},
        sbjhmx: {},
        isNil:'0',
        form: {
          yhbh: null,
          yhmc: '',
          yddz: '',
          yhbz: '9',
          jhyf: null,
          jbflb: '1',
          jhdl: 0,
          sbqx: null,
          jgbm:this.$store.state.user.jgbm,
          dybm:this.$store.state.user.dybm,
        },
        ywlbCheckboxGroup:[],
        xmjsfCheckboxGroup:[],
        ydlbShow:false,
        gzdxx:{
          qdrq: '',
          ywbh: ''
        },
        fileList:[],
        gnid:'1376',
        zfdmOptions: {
          'GFYDLB': [],
          'GFYWLB': [],
          'GFBWDY': [],
          'GFXNFS': [],
        },
        ydlbDefaultIndex:'',
        //类型1为申请,2为查看,3为审核失败再次修改提交
        type: 1,
        sjzzbm:'1R',
      }
    },
    computed: {
      ...mapGetters(['token','jgbm','dybm','czyh','dlzh','yhid','yhsj']),
      typeDisabled(){
        return this.type==2?true:false;
      }
    },
    methods: {
      pageBack() {
        this.$router.go(-1)
      },
      yhbhChangeHandle(yhxx) {
        this.yhbh = yhxx.yhbh;
        this.form.yhbh = yhxx.yhbh;
        this.form.yhmc = yhxx.yhmc;
        this.form.yhdz = yhxx.yhdz;
        this.form.yddz = yhxx.yhdz;
        this.form.jgbm = yhxx.jgbm;
        this.form.dybm = yhxx.dybm;
        this.form.jgbmList = this.form.jgbm.split('').map((item,index)=>{
          return this.form.jgbm.split('').splice(0,index+1).join('');
        });
        this.form.dybmList = this.form.dybm.split('').map((item,index)=>{
          return this.form.dybm.split('').splice(0,index+1).join('');
        });
        this.sjzzbm = yhxx.jgbm.indexOf('1R')<=-1?'1':'1R';
        this.pageSetup();
      },
      async pageSetup() {
        this.$refs['loadingLayout'].setStatus('loading');
        this.$refs['loadingLayout'].setStatus('success');
      },
      async onSubmit(values) {
        if(!this.form.bwdy){
          this.$dialog.confirm({
            title: '提示',
            showCancelButton:false,
            message: '请选择并网电压',
          })
          return;
        }
        if(!this.form.ywlb){
          this.$dialog.confirm({
            title: '提示',
            showCancelButton:false,
            message: '请选择业务类别',
          })
          return;
        }
        if(!this.form.xnfs){
          this.$dialog.confirm({
            title: '提示',
            showCancelButton:false,
            message: '请选择消纳方式',
          })
          return;
        }
        if(!this.form.xmjsfsf){
          this.$dialog.confirm({
            title: '提示',
            showCancelButton:false,
            message: '请选择项目建设方身份',
          })
          return;
        }
        if(!this.$refs['fjUploaderTzfsfzm'].fileList.length){
          this.$dialog.confirm({
            title: '提示',
            showCancelButton:false,
            message: '请上传投资方身份证明材料',
          })
          return;
        }
        if(!this.$refs['fjUploaderYdqszm'].fileList.length){
          this.$dialog.confirm({
            title: '提示',
            showCancelButton:false,
            message: '请上传用电权属证明材料',
          })
          return;
        }
        if(!this.$refs['fjUploaderXmbazm'].fileList.length){
          this.$dialog.confirm({
            title: '提示',
            showCancelButton:false,
            message: '请上传项目备案证明材料',
          })
          return;
        }
        if(this.form.yhbh){
          this.form.xmjsf = this.form.yhmc;
        }else if(!this.form.xmjsf){
          this.$dialog.confirm({
            title: '提示',
            showCancelButton:false,
            message: '请填写项目建设方名称',
          })
          return;
        }
        let params = JSON.parse(JSON.stringify(this.form));
        if (this.gzdxx.qdrq && this.gzdxx.ywbh) {
          params = {
            ...params,
            ywbh:'',
            qdrq:'',
            dqzt:1,
            slry:'',
            sl_time:'',
            slbm:'',
            shjg:'',
            shry:'',
            shrq:'',
            shyy:'',
            ywlsh:'',
            bz:'',
          }
        }
        this.$dialog.confirm({
          title: '温馨提示',
          message: '尊敬的客户，我公司承诺客户填报的个人信息仅供办理电力业务使用，客户需保证所填信息真实、有效，我们将严格保密，保证不向第三者泄露。是否同意提交申请信息？',
        }).then(async () => {
          let res = await ykGfService_addGfywxx(params);
          if(res.code == 200 && res.content.status == 200){
            this.gzdxx = res.content.data;
            setTimeout(() => {
              this.$refs['fjUploaderTzfsfzm'].uploadFileList();
              this.$refs['fjUploaderYdqszm'].uploadFileList();
              this.$refs['fjUploaderXmbazm'].uploadFileList();
              this.$refs['fjUploaderQt'].uploadFileList();
            }, 500);
            this.$toast.success('申请已提交')
          }else{
            this.$dialog.confirm({
              title: '提示',
              showCancelButton:false,
              message: res.content?res.content.message:res.message,
            })
          }
        }).catch(() => {
          this.$dialog.confirm({
            title: '提示',
            showCancelButton:false,
            message: '请带有效身份证件到营业厅咨询办理',
          })
        });
      },
      zzjgConfirm(value,options){
        if(value && value.length){
          this.form.jgbm = value[value.length-1];
          this.form.dybm = '';
          this.form.dybmList = [];
        }
      },
      hsdyConfirm(value,options){
        if(value && value.length){
          this.form.dybm = value[value.length-1];
        }
      },
      onYdlbConfirm(val){
        this.form.ydlbmc = val.dmnr;
        this.form.ydlb = val.dmbh;
        this.ydlbShow = false;
      },
      onYdlbCancel(){
        this.ydlbShow = false;
      },
      fetchZFDM(dmzl) {
        zfdmManageService_getZfdmByDmzl(dmzl).then(res => {
          this.zfdmOptions[dmzl] = res.content.map((item)=>{
            let dmnrArr = item.dmnr.split('-')
            item.dmnr = dmnrArr[dmnrArr.length-1];
            return item;
          });
        });
      },
      async getGfbwDetail(){
        let res = await ykGfywxx_selectiveGetOne({
          ywbh:this.$route.query.ywbh,
        });
        if(res.code == 200){
          this.gzdxx = res.content;
          this.form = res.content;
          this.yhbh = res.content.yhbh;
          this.form.jgbmList = this.form.jgbm.split('').map((item,index)=>{
            return this.form.jgbm.split('').splice(0,index+1).join('');
          });
          this.sjzzbm = this.form.jgbm.indexOf('1R')<=-1?'1':'1R';
          this.$nextTick(()=>{
            this.$refs.hsdySelect.getHsdyList();
          })
          this.form.dybmList = this.form.dybm.split('').map((item,index)=>{
            return this.form.dybm.split('').splice(0,index+1).join('');
          });
          this.zfdmOptions['GFYDLB'].forEach((item,index)=>{
            if(item.dmbh == res.content.ydlb){
              this.ydlbDefaultIndex = index;
              this.form.ydlbmc = item.dmnr;
            }
          })
          this.isNil = /\d/.test(this.form.yhbh)?'0':'1';
        }
      },
      async getFileList(){
        this.fileList = await fileManage_getList({
          ywbh:this.gzdxx.ywbh
        });
      },
      handleIsNilChange(val) {
        if (val == '1') {
          this.form.yhbh = '非系统用户';
          this.form.yhmc = '';
          this.form.yddz = '';
        } else {
          this.form.yhbh = '';
          this.form.yhmc = '';
          this.form.yddz = '';
        }
      },
    },
    async mounted() {
      let loading = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '加载中',
      });
      for(let dmzl in this.zfdmOptions) {
        await this.fetchZFDM(dmzl);
      }
      if(this.$route.query.ywbh){
        this.type = this.$route.query.type;
        await this.getGfbwDetail();
        // 重新申请时不获取之前上传附件,需重新上传
        if(this.type!=3){
          await this.getFileList();
        }
        loading.clear();
      }else{
        this.$store.dispatch('getYhbhList').then(list => {
          if (list.length != 0) {
            this.yhbh = list[0].yhbh;
            this.form.yhbh = list[0].yhbh;
            this.form.yhmc = list[0].yhmc;
            this.form.yhdz = list[0].yhdz;
            this.form.yddz = list[0].yhdz;
            this.form.jgbm = list[0].jgbm;
            this.form.dybm = list[0].dybm;
            this.form.jgbmList = this.form.jgbm.split('').map((item,index)=>{
              return this.form.jgbm.split('').splice(0,index+1).join('');
            });
            this.form.dybmList = this.form.dybm.split('').map((item,index)=>{
              return this.form.dybm.split('').splice(0,index+1).join('');
            });
            this.sjzzbm = this.form.jgbm.indexOf('1R')<=-1?'1':'1R';
          }else{
            this.isNil = '1';
            this.form.yhbh = '非系统用户';
            this.form.yhmc = '';
            this.form.yddz = '';
          }
          loading.clear();
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .van-cell--fj /deep/{
    flex-direction: column;
  }
  .fj-list {
    margin: 10px 0;
    text-align: left;
  }
  .fj-item /deep/{
    padding: 10px 0;
    display: flex;
    border-bottom: 1px solid #eee;
    align-items: center;
    justify-content: space-between;
    .fj-content {
      flex: 1;
      margin-right: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    
    .filename {
      color: #646566;
      font-size: 14px;
    }
    .filesize {
      color: #969799;
      font-size: 12px;
    }

    .fj-actions .van-button {
      padding: 0;
      border: 0;
    }
  }
  .van-cell--gzdfj /deep/ {
    .van-cell__title {
      flex-basis: 75px;
      flex-grow: 0;
    }
  }
  .preview-cover {
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 4px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
  }
</style>