// WaGen___HASHKEY__4e73758_2023-10-23 09:28:13 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
未提供注释
*/
/* ---
新建光伏并网申请表
*/
export function ykGfService_addGfywxx(ykGfywxx, meta) {
	return fetch({
		url : 'psdmsschjy/service/YkGfService/addGfywxx',
		method : 'post',
		headers : {
		},
		data : {
			param : ykGfywxx //YkGfywxx
		}
	})
}

/* ---
光伏并网申请表受理
*/
export function ykGfService_acceptGfywxx(ykGfywxx, meta) {
	return fetch({
		url : 'psdmsschjy/service/YkGfService/acceptGfywxx',
		method : 'post',
		headers : {
		},
		data : {
			param : ykGfywxx //YkGfywxx
		}
	})
}

/* ---
光伏并网申请表作废
*/
export function ykGfService_cancelGfywxx(ykGfywxx, meta) {
	return fetch({
		url : 'psdmsschjy/service/YkGfService/cancelGfywxx',
		method : 'post',
		headers : {
		},
		data : {
			param : ykGfywxx //YkGfywxx
		}
	})
}

/* ---
光伏并网审核通过
*/
export function ykGfService_passGfywxx(ykGfywxx, meta) {
	return fetch({
		url : 'psdmsschjy/service/YkGfService/passGfywxx',
		method : 'post',
		headers : {
		},
		data : {
			param : ykGfywxx //YkGfywxx
		}
	})
}

/* ---
光伏并网审核不通过
*/
export function ykGfService_notPassGfywxx(ykGfywxx, meta) {
	return fetch({
		url : 'psdmsschjy/service/YkGfService/notPassGfywxx',
		method : 'post',
		headers : {
		},
		data : {
			param : ykGfywxx //YkGfywxx
		}
	})
}

/* ---
查工单审核记录
*/
export function ykGfService_getHsitoryByYwbh(ywbh, meta) {
	return fetch({
		url : 'psdmsschjy/service/YkGfService/getHsitoryByYwbh',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				ywbh : ywbh //String
			}
		}
	})
}

